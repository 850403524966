.btn {
    border-radius: 0 !important;
    overflow: hidden;
    font-size: 0.875rem;
    box-sizing: border-box;
    min-height: 48px;
    text-align: center;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 500;
    line-height: 1.75;
    white-space: normal;
    background-color: transparent;
    letter-spacing: 0.02857em;
    min-width: 160px;
    border-left-width: 0;
    border-right-width: 0;
    border-top: 5px solid rgba(0, 0, 0, 0);
}

.tab-link {
    min-width: 125px !important;
    font-weight: normal;
}

    .tab-link.active {
        font-weight: bold !important;
        color: #2e364c !important;
        background-color: #fff;
    }

    .tab-link:focus {
        box-shadow: none;
    }

    .tab-link:hover {
        cursor: default;
    }


