.form-isnt-available {
  background-color: #D8D8D8;
  width: 100%;
  height: 100%;
}

.logo-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #11334D;
  width: 100%;
  height: 150px;
  position: relative;
}

.logo {
  position: absolute;
  width: 400px;
}

.vector {
  position: relative;
  width: 438px;
  left: -320px;
}

.content {
  color: #292B2C;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  color: #292B2C;
  padding-top: 50px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

p {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

