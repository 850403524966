@media (max-width: 600px) {
  .success-text-block {
    width: 345px;
  }

  .logo-wrapper-upload {
    margin-bottom: 40px;
  }
}

@media (min-width: 600px) {
  .success-text-block {
    width: 600px;
  }

  .logo-wrapper-upload {
    margin-bottom: 48px;
  }
}

.success-text-block {
  height: 285px;
  margin: auto;
  border-radius: 8px;
  background-color: #fff;
  
  font-size: 16px;
  font-weight: 600;
  font-family: 'Segoe UI';
  text-align: center;
}

.success-text {
  margin-top: 96px;
}

.complete-upload-logo {
  margin-bottom: 24px;
  width: 48px;
}