/* ************************ */
/*							*/
/*	custom theme			*/
/*							*/
/* ************************ */

@media (max-width: 1199px) {
	/* md */

}

@media (max-width: 991px) {
	/* sm */
	html{font-size:14px;}

	.container{ width:auto; padding-left:15px; padding-right:15px;}

}


@media (max-width: 767px) {
	/* xs */

}

@media (max-width: 479px) {
	.header-row [class*="col-"]{display: block; text-align: center;}
	.header-section .team-logo{margin-right: 0; margin-bottom: 1.5rem; margin-left: auto; margin-right: auto;}
	.nav-link-wrap, .form-header:not(:first-child){margin-top: 2.5rem;}

	.flex.form-group{display: block;}
	.upload-photo{margin-right: auto; margin-left: auto;margin-bottom: 15px;}
}

@media (max-width: 320px) {
	#page-wrap{border-left:2px dashed red;}


}
