/* ************************ */
/*							*/
/*	custom theme			*/
/*							*/
/* ************************ */

/* ************************* */
/* GLOBAL settings			 */
/* ************************* */

	/* general */
	html{font-size:16px;}
	html, body { height: 100%; }
	body *	{ position:relative; box-sizing:border-box; -moz-box-sizing:border-box }
body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    overflow-x: hidden;
    font: 1rem/1.5;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
    font-weight: 400;
    color: #292B2C;
    background-color: #657d69;
    background-color: #657d69;
    display: flex;
    flex-direction: column;
}


	/* typography */
	h1, h2, h3, h4, h5, h6	{
		font-family:inherit;
		font-weight:700;
		color: inherit;
		line-height:1.2;
		color: #565c6d;
		margin: 0;
		margin-bottom: 1rem;
	}
	h1{font-size:1.5rem /*24px*/;}
	h2, .h2-style{font-size:1.5rem /*24px*/;}
	h3, .h3-style{font-size:1.87rem /*30px*/;}
	h4, .h4-style{font-size:1.56rem /*25px*/;}
	h5, .h5-style{font-size:1rem /*16px*/;}
	h6, .h6-style{font-size:1.12rem /*18px*/;}

	.bg-white{background-color: #fff;}

	.textbox:not(:last-child){margin-bottom: 1.5rem;}

	/* links */
	a {color:#e48000; transition:color 0.2s ease, background-color 0.2s ease;}
	a:hover, A:focus{color:#985500; text-decoration:underline;}

	a img{border:0;}

	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a{ color:inherit;}
	h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, h1 a:focus, h2 a:focus, h3 a:focus, h4 a:focus, h5 a:focus, h6 a:focus{color:red;}

	.mb-big{margin-bottom: 3.5rem;}


	/* form */
	/*.form-control{font-size:1rem; padding:0.3rem 0.5rem; height:2rem;}
	.form-control:focus{outline:0;}
	.form-control::-ms-clear{display: none;}

	select.form-control::-ms-expand {display: none;}*/
	select[multiple], select[size] {height: 100%;}

	textarea {resize: vertical; min-height:5rem;}

	label{ font-weight:normal; line-height:1.4;}

	.form-group, .radio, .checkbox{margin-top:0;}

	.btn {transition:color 0.2s ease, border-color 0.2s ease, background-color 0.2s ease; }
	.btn:focus{outline:0;}
	.btn-default{
		color:white;
		border-color:#00548a;
		background: #0072bc;
	    background: -webkit-linear-gradient(top, #0072bc, #00548a);
	    background: -ms-linear-gradient(top, #0072bc, #00548a);
	    background: linear-gradient(top, #0072bc, #00548a);
		}
	.btn-default:hover, .btn-default:focus, .focus.btn-default, .btn-default:active, .active.btn-default, .open > .btn-default.dropdown-toggle{
		color:white;
		border-color:#0072bc;
		background: #008ce7;
	    background: -webkit-linear-gradient(top, #008ce7, #0072bc);
	    background: -ms-linear-gradient(top, #008ce7, #0072bc);
	    background: linear-gradient(top, #008ce7, #0072bc);
		}

	.textbox .btn{margin-top:0.35rem; margin-bottom:0.35rem;}


	.header-section{background-color: #cdd2df; /*border-top: 8px solid #cdbda2; padding-top: 1.5rem; padding-bottom: 1.5rem;*/}
	.header-row [class*="col-"]{display: flex; align-items: flex-start;}
	.header-section .team-logo{width: 120px; flex: 0 0 auto; margin-right: 2rem;}
	.header-section .team-logo img{width: 100%;}

	.form-section{padding-top: 3rem; padding-bottom: 3rem; background-color: #fff;}
	.header-section+.content-section{/*border-top: 5px solid #ecedf9;*/}

.content-section {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

    #root, .content-section > .container, .content-section > .container > .row, .form-section {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
    }

	.flex.form-group{display: flex; align-items: center;}

	.upload-photo {
	  width: 95px;
	  height: 95px;
	  border-radius: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  background: #f1f1f1;
	  color: #e2e4ee;
		font-size: 2rem;
		margin-right: 2rem;
		margin-bottom: 0;
	}

	.flex .form-group-wrap{flex: 1 0 auto;}
	.flex .form-group-wrap .input-wrap:not(:last-child){ margin-bottom: 15px;}

	.form-header:not(:first-child){margin-top: 3.5rem;}
	.form-section-header+.form-header{margin-top: 0;}

	.input-label{font-size: 0.87rem;}
	.input-label.required:after{
		content: "*";
    margin-left: 2px;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: #e40000;
	}

	.required-text{color: #e40000; font-size: 0.87rem; text-align: right; margin-top: -0.4rem;}

	.nav-link-wrap{display: flex; align-items: center; justify-content:space-between; margin-top: 3.5rem;flex-direction:row-reverse;}
	.nav-link-wrap .nav-link{font-weight: 700; color: #565c6d !important;cursor: pointer;}
	.nav-link-wrap .nav-link .fa:first-child{margin-right: 0.5rem;}
	.nav-link-wrap .nav-link .fa:last-child{margin-left: 0.5rem;}

	.sep{width: 100%; height: 1px; background-color: #f1f1f1; margin-top: 3rem; margin-bottom: 3rem;}
	.sep:last-child{margin-bottom: 0;}
	.sep:first-child{margin-top: 0;}
