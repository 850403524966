.header-row {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.inline-field {
    display: flex;
    justify-content: space-between;
}

.box-shadow-none {
    box-shadow: none !important;
}

.inline-field-label {
    display: inline-flex;
    flex: 1 1 25%;
    align-items: center;
}

.inline-field-control {
    flex: 1 1 75%;
    display: inline-flex;
    align-items: center;
}

.inline-field:not(:last-child) {
    margin-bottom: 1rem;
}

.field-header.inline-field-label {
    margin-bottom: 0px;
}

.inline-label {
    padding-top: 5px;
}

.field-header.col-md-2 {
    margin-top: 0.3rem;
}

.field-group-area {
    padding: 16px;
    margin-bottom: 32px;
    background-color: #FAFAFA;
}

.field-container {
   padding-left: 16px;
   padding-right: 16px;
}

a.tournament-remove {
    vertical-align: sub;
}

div.tournament-remove {
    position: absolute;
    right: -4px;
    top: -8px;
}

.require::after {
    content: "*";
    margin-left: 2px;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: #e40000;
}

/*.tournament-remove {
    padding-top: 5px;
    padding-left: 5px;
}*/

.avatar-area {
    margin-left: auto;
    margin-right: auto;
}

.avatar-label {
    margin-bottom: 0;
    margin-top: 14px;
}

.btn-user {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    background: #89d0ef !important;
    color: rgb(255 255 255/80%) !important;
    line-height: 120px;
    font-size: 2rem;
}

.btn-athlete {
    font-weight: 700 !important;
    text-transform: uppercase !important;
}

.img-circular {
    background-size: cover;
    background-position: 50%;
    -webkit-border-radius: 50%;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    cursor: pointer;
}

.avatar-container {
    display: flex;
}

.name-container {
    flex: 1;
}

.new-submit {
    color: #e48000;
    text-decoration: none;
    background-color: transparent;
}

.ellipsis-attachment-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 70%;
    display: inline-block;
}

.file-weight {
    font-size: 0.7rem;
    margin-top: 6px;
}

.file-remove {
    margin-top: 6px;
    cursor: pointer;
}

.upload-container {
    background: #ecedf9;
}

.panel-item {
    padding: 10px 14px;
}

.collapse-panel {
    cursor: pointer;
}

.field-header {
    word-break: break-word;
    color: #292B2C;
    font-size: 16px;
    font-weight: 600;
}

.field-description {
    word-break: break-word;
}

.bool-field {
    display: flex;
    justify-content: flex-start;
}

.field-complex-header {
    font-size: 16px;
    font-weight: 500;
    color: #5f6a8a;
}

.form-header {
    color: #292B2C !important;
    font-size: 20px !important;
    font-weight: 700 !important;
}

.field-description {
   color: #292B2C;
   font-size: 16px;
   font-weight: 400;
}

.k-checkbox-label::before {
    border-color: #b5bbcd !important;
    margin-top: 4px !important;
}

.k-checkbox-label::after {
    margin-top: 5px !important;
}

.k-textbox:disabled,
.k-textbox[disabled] {
    background-color: lightgray;
}

.disable-submit {
    opacity: 0.7;
}

.k-upload.k-state-invalid {
    color: #dc3545;
    border-color: #dc3545;
}

.state-invalid {
    color: #dc3545;
    border-color: #dc3545;
}

.bool-field .k-checkbox {
    min-width: 16px;
    margin-right: 5px;
}

@media (max-width: 365px) {
    .disabled-navigation-sm .k-calendar-navigation {
        display: none;
    }
}

.k-checkbox-label {
    margin-top: -0.15em;
}

.k-panelbar {
    margin-bottom: 16px;
}

.header-wrap.forms-header h1,
.header-wrap.forms-header .textbox {
    word-break: break-word;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
}

/* Initializing */

.initializing {
    background: white;
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.initializing-spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #E48000 !important;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: initializing-spinner 2s linear infinite;
    flex-shrink: 0;
}

.avatar-menu-container {
    position: absolute;
    overflow: hidden;
    height: 56px;
    width: 100%;
    bottom: 0;
    margin: -58px 0 0px;
    line-height: 14px;
}

.recruit-avatar-width {
    width: 140px !important;
}

recruit-avatar-width-add {
    width: 128px !important;
}

.parent-avatar-menu:hover .avatar-menu {
    opacity: 1;
    filter: none;
    margin-top: 0;
}

.parent-round-avatar-menu .avatar-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.parent-round-avatar-menu .avatar-menu-container {
    height: 58%;
    width: 100%;
    bottom: 0;
    margin: 0px;
}

.avatar-menu-item {
    text-align: center;
    font-size: 12px;
    cursor: pointer;
}

.content-section {
    overflow: hidden;
}

.avatar-menu {
    color: #fff;
    font-weight: normal !important;
    opacity: 0;
    filter: alpha(opacity=0);
    background-color: rgba(29, 32, 34, .7);
    width: 100%;
    margin-top: 40px;
    transition: all 200ms ease-in-out;
    border-radius: 0 0 2px 2px;
    padding: 5px 0;
    height: 79px;
    text-transform: none !important;
    cursor: pointer;
}

.single-item {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@keyframes initializing-spinner {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.border-none {
    border: none;
    background-color: white;
}

.common-but {
    width: 100px;
    background-color: #e4e7eb;
    margin: 0 5px;
    border-radius: 4px;
}

.d-inline-block {
    display: inline-block;
}

.institution-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.radio-selector-container {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: inline-flex;
    align-items: center;
    color: #212121;
    cursor: pointer;
}

.radio-btn-indent {
    margin-right: 8px;
}

.radio-selector-container-indent {
    margin-right: 24px;
}

.search-item-media {
    display: flex;
}

.search-item-media-avatar {
    width: 45px;
    height: 45px;
    background: #11334D;
    border-radius: 50%;
    color: #fff;
    font-size: 18px;
    text-align: center;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    line-height: 45px;
    margin-right: 16px;
}

.search-item-media-container-wrap {
    display: flex;
    align-items: center;
}

.search-item-info-name {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #212121;
}

.search-item-info-category {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #6C757D;
}

.search-item-address-wrap {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display:  block;
    align-items: center;
    text-align: right;
    color: #212121;
}


.search-item-media {
    display: flex;
    justify-content: space-between;
    padding: 7.5px 20px;
    cursor: pointer;
}

.search-item-divider {
    border-bottom: 1px solid #CCD6E3;
}

.results-wrap {
    border: 1px solid #CCD6E3;
    box-shadow: 0px 8px 8px -4px rgba(17, 51, 77, 0.08), 0px 4px 6px -4px rgba(17, 51, 77, 0.12);
}

.no-search-result {
    padding: 20px 24px;
    text-align: center;
    color: #6C757D;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
}

.add-manually-label {
    color: #007BFF !important;
    cursor: pointer;
}

.add-manually-label:hover {
    text-decoration: underline;
}

.skeleton {
    animation: skeleton-loading 0.7s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.empty-manually-label {
    height: 17px;
}

.parent-wrap {
    position: absolute;
    z-index: 2;
    width: 100%;
    background: #fff;
    margin-top: 5px;
    max-height: 306px;
    overflow-y: auto;
}

.loading-container {
    height: 57px;
}

.institution-tag {
    border-radius: 2px;
    background-color: #565c6d;
    padding: calc(0.125rem + 1px) 0.8rem;
    margin: 0.25rem 0 0 0.25rem;
    cursor: default;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    line-height: inherit;
    white-space: normal;
    text-align: center;
    text-decoration: none;
    justify-content: center;
    vertical-align: middle;
    user-select: none;
    outline: none;
    z-index: 1;
    color: #fff;
    font-size: 1rem !important;
    position: absolute;
}

.institution-tag-close {
    cursor: pointer;
    position: relative;
    font-size: 12px;
    left: 5px;
    top: 2px;
}

.form-group .cellphone-checkbox {
    color: red;
    width: 100%;
}

.k-panelbar>.k-item>.k-link.k-state-selected {
    border-color: #dde2e7 !important;
    color: #212529 !important;
    background-color: #f8f9fa !important;
}

span.k-link.k-header.k-state-selected {
    box-shadow: inset 0 0 0 0.5px #e3e7eb !important;
}

a.collapse-panel:focus, a.collapse-panel:hover{
    color: inherit;
    text-decoration: none;
}

.dialog-actions-container {
    position: relative;
    bottom: -44px;
    display: inline-flex;
    width: 100%;
}

.dialog-actions-container.justify-end {
    justify-content: end;
}

.dialog-actions-container.justify-between {
    justify-content: space-between;
}

.dialog-text {
    margin: 11px 0 -3px;
}

.dialog-action {
    width: 130px;
}