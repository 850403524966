@media (max-width: 991px) {
  html{font-size:16px;}
}

@media (max-width: 600px) {
  .content-upload {
    width: 345px;
  }

  .wrapper-selector {
    width: 297px;
  }

  .k-form-fieldset.form-upload {
    width: 100%;
    padding: 0 24px;
  }

  legend.k-form-legend {
    padding: 0;
  }

  .submit-button {
    right: 0;
  }

  .logo-wrapper-upload {
    margin-bottom: 40px;
  }
}

@media (min-width: 600px) {
  .content-upload {
    width: 600px;
  }

  .form-upload {
    width: 552px;
  }
  
  .wrapper-selector {
    width: 552px;
  }

  .submit-button {
    right: 24px;
  }

  .logo-wrapper-upload {
    margin-bottom: 48px;
  }

  .success-text-block {
    width: 600px;
  }
}

.logo-wrapper-upload {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 245px;
  height: 54px;
  position: relative;
  margin: 0 auto;
}

.content-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 8px;
  min-height: 524px;
  background-color: #fff;
}

.layout-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;  
  height: 100%;
  background-color: #F7F8FB;
}


.label-upload {
  width: 100%;
}

.input-upload {
  width: 100%;
  margin-bottom: 24px;
}

.description-upload {
  margin-top: 48px;
  margin-bottom: 24px;
}

.submit-upload {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.validation-text {
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
  color: #BB4430;
}

.k-textbox-container {
  width: 100%;
}

.k-form-field {
  margin-bottom: 24px;
}

.k-textbox-container label.k-label {
  color: #12151D;
  margin-bottom: 10px;
}

input.k-form-label.k-textbox {
  background-color: #F7F8FB !important;
  font-weight: 400;
  font-family: 'Segoe UI';
  font-size: 14px;
  border-color: #CCD6E3;
  border: 0.5px;
  margin-top: 10px;
}

label.k-label{
  color: #12151D;
  font-weight: 600;
  font-family: 'Segoe UI';
  font-size: 16px;
}

.legend-text {
  font-weight: 400;
  font-family: 'Segoe UI';
  font-size: 14px;
  color: #677294;
}

legend.k-form-legend {
  padding-top: 48px;
  padding-bottom: 24px;
  margin-bottom: 0;
  width: 100%;
  color: #12151D;
  font-weight: 600;
  font-family: 'Segoe UI';
  font-size: 16px;
}

.k-form-label.k-textbox.k-state-invalid {
  color: #677294;
  border-color: #CCD6E3;
  font-weight: 400;
  font-family: 'Segoe UI';
}

.k-button {
  background-color: #12151D;
  color: #FFF;
  font-weight: 600;
  font-family: 'Segoe UI';
  font-size: 13px;
  border-radius: 4px;
}

.k-button:hover {
  background-color: #c8e0f2;
  color: #677294;
}

.upload-selector {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  margin-top: 24px;
  padding-bottom: 48px;
}
.upload-selector-text {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size:  16px;
}

.k-widget.k-upload.k-header {
  border: none;
}

.k-upload .k-dropzone {
  background-color: #FFF;
}
.upload-selector-button {
  width: 100px;
}

.k-upload-status .k-upload-status-total {
  display: none;
}

.submit-button {
  top: 48px;
  display: flex;
  justify-content: end;
}

.form-wrapper {
  display: flex;
  justify-content: center;
}

.k-state-invalid .require::after {
  content: "";
}

.file-state-invalid {
  color: #BB4430;
  font-weight: 600;
  font-family: 'Segoe UI';
  font-size: 16px;
}

.upload-selector-button .k-widget .k-upload .k-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.k-upload-files .k-reset {
  width: 400px;
}

.k-form fieldset {
  border: none;
}

.chosen-files-container {
  margin-top: 24px;
}

.k-d-flex-row {
  color: #BB4430;
  font-weight: 600;
  font-family: 'Segoe UI';
  font-size: 16px;
}

.selected-file {
  color: #BB4430;
  font-weight: 400;
  font-size: 14px;
}

.selected-size {
  margin-top: 4px;
  color: #677294;
  font-weight: 400;
  font-size: 10px;
  margin-left: 16px;
}

.k-d-flex-row .k-justify-content-stretch {
  margin: 24px 0;
}

.file-name {
  width: 464px;
  margin-left: 16px;
  font-weight: 400;
  font-size: 14px;
  color: #397367;
}

.file-name-wrapper {
  width: 497px; 
}

.file-row {
  display: flex;
  flex-direction: row;
}

.error-text {
  color: #BB4430;
}

.k-textbox-container>.k-label, .k-textbox-container.k-state-focused>.k-label {
  transform: translate(-0.75rem, -0.28125rem) translate(-1px, -1.125em) translate(-12.5%, -9.375%) !important;
}

.k-textbox-container>.k-label {
  left: calc(0.75rem + 6px) !important;
}

.upload-selector-text p {
  margin: 0;
}

.wrapper-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}

div.k-form-error .k-text-start {
  color: #BB4430;
  font-weight: 600;
  font-family: 'Segoe UI';
  font-size: 16px;
}

.color-error {
  color: #BB4430;
  font-weight: 600;
  font-family: 'Segoe UI';
  font-size: 16px;
}

.form-group {
  margin-bottom: 0;
  padding-bottom: 24px;
}

.k-form {
  padding: 0;
}