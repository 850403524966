.unsubscribe-header {
    color: #11334D;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 1em;
    left: 36%;
    top: 75px;
    width: 500px;
    text-align: center;
}

.unsubscribe-content {
    background-color: #E5E5E5;
    height: 100%;
}

.unsubscribe-body {
    left: 36%;
    top: 60px;
    width: 500px;
    height: 500px;
    padding-left: 20px;
    background-color: #FFFFFF;
}

    .unsubscribe-body .bold {
        font-weight: 600;
    }
    .unsubscribe-body .body-content {
        width: 500px;
        height: auto;
    }

        .unsubscribe-body .body-content .body-header {
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 1em;
            padding-top: 20px;
        }

        .unsubscribe-body .body-content .green-body-header {
            color: #20C997;
        }

        .unsubscribe-body .body-content .body-email {
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 2em;
        }

        .unsubscribe-body .body-content .body-info {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 8em;
        }

            .unsubscribe-body .body-content .body-info .comb-line {
                display: flex;
                justify-content: left;
            }

                .unsubscribe-body .body-content .body-info .comb-line > div {
                    margin-left: 5px;
                }

            .unsubscribe-body .body-content .body-info .unsubscribed-icon {
                left: 30%;
                top: 60px;
                margin-bottom: 2em;
            }

        .unsubscribe-body .body-content .body-bottom {
            margin-bottom: 2em;
        }

            .unsubscribe-body .body-content .body-bottom .main-button {
                background-color: #11334d !important;
                color: #fff !important;
                transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease;
                line-height: 1.21 !important;
                border-radius: 3px !important;
                box-shadow: none !important;
                border: none;
                outline: none;
                padding: 8.5px 16px;
                cursor: pointer;
                font-size: 13px;
                font-weight: 600;
            }

            .unsubscribe-body .body-content .body-bottom .main-button:hover {
                background-color: #0578D3 !important;
            }

        .unsubscribe-body .body-content .body-keep-receiving-link a {
            color: #007bff;
            transition: none;
        }

        .unsubscribe-body .body-content .body-keep-receiving-link a:hover {
            color: #0056b3;
        }

.header-text {
    left: 36%;
    top: 35%;
}

img.icon-top-part {
    padding-bottom: 20px;
    padding-right: 20px;
}

img.icon-bottom-part {
    left: 83px;
    top: 9px;
}

img.header-decorator {
    position: absolute;
    left: 21%;
}